<template>
	<div>
		<h4>This verification has already been used, please try to login again.</h4>
	</div>
</template>

<script>
export default {
	name: "InvalidToken"
};
</script>

<style scoped>
h4 {
	text-align: center;
}
</style>
